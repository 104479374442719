<template>
  <div id="aboutFestival">
    <app-navbar></app-navbar>
    <div class="box-yellow"></div>
    <div class="yellowTwoBallonHorizont">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/yellowTwoBallonHorizont.png"
           alt="yellowTwoBallonHorizont">
    </div>
    <div class="CrossRed">
      <img v-parallax="0.1" class="img-fluid" src="@/assets/CrossRed.png"
           alt="yellowTwoBallonHorizont">
    </div>
    <div class="container">
      <h2 class="text-center">{{ "aboutFestival.label" | staticTranslation }}</h2>
      <div class="col-lg-10 offset-lg-1">
        <h3>{{ "aboutFestival.citation" | staticTranslation }}</h3>
        <h5 class="text-right">Olda Krikl</h5>
        <p class="pt-lg-4">{{ "aboutFestival.textOne" | staticTranslation }}</p>
        <p>{{ "aboutFestival.textTwo" | staticTranslation }}</p>
<!--        <p>{{ "aboutFestival.textThree" | staticTranslation }}</p>-->
<!--        <p>{{ "aboutFestival.textFour" | staticTranslation }}</p>-->
<!--        <p class="pb-lg-4">{{ "aboutFestival.textFive" | staticTranslation }}</p>-->
<!--        Schovaný odkaz na Program-->
<!--        <div class="text-center padding-bottom">-->
<!--          <a class="ml-lg-4 mt-lg-4 size-ticket text-center" href="/program">Program 2021</a>-->
<!--        </div>-->
<!--        <h4 class="text-center">{{ "aboutFestival.textThree" | staticTranslation }}</h4>-->
<!--        <div class="padding-bottom">-->
<!--        <h5 class="text-center" v-for="genre in genres" v-bind:key="genre.name | translation">{{ genre.name | translation }}</h5>-->
<!--        </div>-->
      </div>
      <div class="text-center zIndex pt-lg-4">
        <img v-parallax="0.1" class="img-fluid logoBrnoUnesco zIndex"
             src="@/assets/sponsors/Logo_Brno_Unesco-300x130.png" alt="Logo_Brno_Unesco">
      </div>
      <div v-if="isActiveCs" class="pb-lg-4 text-center media-print">
        <a class="report" href="/aboutFestivalFiles/Rocni-zprava_MHB-2021.pdf" download>
          Výroční zpráva 2021</a>
        <a class="report" href="/aboutFestivalFiles/Rocni-zprava_MHB-2020.pdf" download>
          Výroční zpráva 2020</a>
        <a class="report" href="/aboutFestivalFiles/Rocni-zprava_MHB-2019.pdf" download>
          Výroční zpráva 2019</a>
        <a class="report" href="/aboutFestivalFiles/Rocni-zprava_MHB-2018.pdf" download>
          Výroční zpráva 2018</a>
        <a class="report" href="/aboutFestivalFiles/Rocni-zprava_MHB-2017.pdf" download>
          Výroční zpráva 2017</a>
      </div>
    </div>

    <div class="text-left">
      <img v-parallax="0.2" class="img-fluid pl-lg-4" width="5%" src="@/assets/whiteBallone.png" alt="Logo_Brno_Unesco">
    </div>

    <app-slider-component
        :slides="sliderPhoto"
        :time="3000"
        :has-arrows="true"
    >

    </app-slider-component>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import SliderComponent from "@/components/base/pageHome/SliderComponent";
import axios from "@/api/axiom-instance";

export default {
  name: "aboutFestival",
  components: {
    'app-navbar': navbar,
    'app-footer': footer,
    'app-slider-component': SliderComponent
  },
  data() {
    return {
      genres: [],
      sliderPhoto: ["/sliderAbout/1.jpg", "/sliderAbout/2.jpg", "/sliderAbout/3.jpg", "/sliderAbout/4.jpeg", "/sliderAbout/5.jpg"]
    }
  },
  created() {
    axios.get("/genre/list").then((success) => {
      this.genres = success.data;
    }).catch((failed) => {
      console.log(failed)
    })
  },
  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  }
}
</script>

<style scoped>
.zIndex {
  z-index: 10;
}
.padding-bottom {
  padding-bottom: 4rem;
}
h1 {
  padding-top: 3em;
  font-size: 3.5em;
  color: black;
  font-family: "Transcript Pro";
}

h2 {
  padding-top: 4em;
  font-weight: bold;
  font-size: 2.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h3 {
  padding-top: 2.5em;
  font-size: 2em;
  color: #152326;
  font-family: "Transcript Pro";
}

h4 {
  color: #152326;
  font-family: "Transcript Pro";
}

h5 {
  color: #152326;
  font-family: "Transcript Pro";
}

p {
  color: #152326;
  font-family: "Transcript Mono";
}

a {
  color: #152326;
  border: solid 2px #152326;
  padding: 8px 9px 8px 9px;
  font-size: 2.1em;
  font-family: "Transcript Pro";
}

a:hover {
  color: #eae7d6;
  background-color: #152326;
  text-decoration: none;
  font-size: 2.1em;
  font-family: "Transcript Pro";
}

.logoBrnoUnesco {
  width: 30%;
  padding-bottom: 5em;
}

.box-yellow {
  position: absolute;
  height: 100vh;
  text-align: end;
  top: 80%;
  right: 0;
  width: 65%;
  background-color: #f3ba53;
}

#aboutFestival {
  color: #f3ba53;
  background-color: #ed764e;
}

.yellowTwoBallonHorizont {
  position: absolute;
  width: 25%;
  top: 20vh;
  left: -8%;
}

.CrossRed {
  position: absolute;
  width: 16%;
  top: 100vh;
  text-align: end;
  right: 4%;
}

.media-print {
  transform: translate3d(0px, -6.55px, 0px);
}

.media-print > a {
  margin: 2rem auto;
  width: 70%;
  display: block;
}
/*Responzive - media queries*/
@media screen and (max-width: 500px) {
  h2 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 600px) {
  .imgAboutFestival {
    padding: 10px 6px;
  }
}

@media screen and (max-width: 767px) {
  .imgAboutFestival {
    padding: 20px 12px;
  }
}
</style>